export class Select {
    constructor(elem) {
        this.elem = elem;
        this.filter = this.elem.hasAttribute('filter');
        this.multiple = this.elem.hasAttribute('multiple');
        this.placeholder = this.elem.getAttribute('placeholder') ?? false;
        this.handler = new Choices(elem, {
            searchEnabled: this.filter,
            allowHTML: false,
            duplicateItemsAllowed: false,
            delimiter: ';',
            shouldSort: false,
            removeItemButton: this.multiple,
        })

        this.elem.select = this;
    }
}

window.addEventListener('load', () => {
    if (!window.nrg) window.nrg = {};
    window.nrg.selects = [];
    document.querySelectorAll('select:not([disabled]):not([nochoice])').forEach(item => {
        window.nrg.selects.push(new Select(item));
    })
})